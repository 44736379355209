import { useContext, createContext, useState } from "react";
import settings from "settings/application.json";

const ConfigContext = createContext(settings);

const ConfigProvider = ({ children }) => {
	const [config, setConfig] = useState(settings);
	return (
		<ConfigContext.Provider value={{ config, setConfig }}>
			{children}
		</ConfigContext.Provider>
	);
};

export default ConfigProvider;

export const useConfig = () => {
	return useContext(ConfigContext);
};
