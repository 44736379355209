import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CountDown from "components/CountDown";
import NavBar from "components/NavBar";

export default function Header({
	weddingDetails,
	language,
	backgroundImage,
	font,
	items,
}) {
	const [imageSrc, setImageSrc] = useState(null);

	useEffect(() => {
		import(`assets/images/${backgroundImage}`)
			.then((image) => setImageSrc(image.default))
			.catch((err) => console.log("Error loading image:", err));
	}, [backgroundImage]);

	const title = `${
		weddingDetails?.bride?.name?.charAt(0).toUpperCase() ?? "B"
	}&${weddingDetails?.groom?.name?.charAt(0).toUpperCase() ?? "G"}`;

	return (
		<Box
			position='relative'
			height='100vh'
			width='100vw'
			display='flex'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			textAlign='center'
			overflow='hidden'
			flex='1'
			sx={{
				backgroundImage: `url(${imageSrc})`,
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
				backgroundSize: "cover",
				width: "100vw",
				height: "100vh",
			}}>
			<NavBar font={font} title={title} items={items} />
			<CountDown
				endDate={weddingDetails?.date}
				language={language}
				font={font}
			/>
		</Box>
	);
}
