import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export default function Admin() {
	return (
		<Box>
			<Typography>This is the admin page</Typography>
		</Box>
	);
}
