import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "hooks/AuthProvider";

const PrivateRoute = () => {
	const auth = useAuth();
	const [isValid, setIsValid] = useState(false);
	const [checking, setChecking] = useState(true);

	useEffect(() => {
		const validate = async () => {
			try {
				const response = await auth.validateToken();
				console.log(response);
				setIsValid(response);
			} catch (error) {
				setIsValid(false);
			} finally {
				setChecking(false);
			}
		};

		validate();
	}, []);

	if (checking) return <div>Verifying...</div>;

	if (!isValid) return <Navigate to='/signin' />;

	return <Outlet />;
};

export default PrivateRoute;
