import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CountDownItem from "./CountDownItem";
import CountDownGrid from "./CountDownGrid";
import Texts from "./CountDownTexts/languages.json";

const calculateTimeLeft = (date) => {
	const seconds = 1000;
	const minutes = seconds * 60;
	const hours = minutes * 60;
	const days = hours * 24;
	const now = new Date().getTime();
	const end = date ? new Date(date)?.getTime() : now;
	const difference = end - now;

	return {
		daysLeft: Math.floor(difference / days),
		hoursLeft: Math.floor((difference % days) / hours),
		minutesLeft: Math.floor((difference % hours) / minutes),
		secondsLeft: Math.floor((difference % minutes) / seconds),
	};
};

export default function CountDown({ endDate, font, language }) {
	const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(endDate));

	const texts = Texts[language] ?? Texts["en-gb"];

	useEffect(() => {
		const second = 1000;
		const interval = setInterval(() => {
			setTimeLeft(() => calculateTimeLeft(endDate));
			return () => clearInterval(interval);
		}, second);
	}, [timeLeft, endDate]);

	return (
		<Box justifyContent='center' alignItems='center'>
			<CountDownGrid font={font}>
				<CountDownItem value={timeLeft.daysLeft} text={texts.days} />
				<CountDownItem value={timeLeft.hoursLeft} text={texts.hours} />
				<CountDownItem value={timeLeft.minutesLeft} text={texts.minutes} />
				<CountDownItem value={timeLeft.secondsLeft} text={texts.seconds} />
			</CountDownGrid>
		</Box>
	);
}
