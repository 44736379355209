import { React, Children, cloneElement } from "react";
import Grid from "@mui/material/Grid";

export default function CountDownGrid({ children, font }) {
	return (
		<Grid sx={{ flexGrow: 1 }} container spacing={2}>
			<Grid container spacing={1} sx={{ justifyContent: "center" }}>
				{Children.map(children, (child) => {
					return cloneElement(child, { font: font });
				})}
			</Grid>
		</Grid>
	);
}
