import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LoginContainer from "components/Login/Container";
import LoginCard from "components/Login/Card";
import { Link as RouterDomLink } from "react-router-dom";
import { useAuth } from "hooks/AuthProvider";

const LinkBehavior = React.forwardRef((props, ref) => (
	<RouterDomLink ref={ref} to='/signin' {...props} />
));

export default function Registry({ settings }) {
	const auth = useAuth();
	const [emailError, setEmailError] = React.useState(false);
	const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
	const [passwordError, setPasswordError] = React.useState(false);
	const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
	const [nameError, setNameError] = React.useState(false);
	const [nameErrorMessage, setNameErrorMessage] = React.useState("");

	const validateInputs = () => {
		const email = document.getElementById("email");
		const password = document.getElementById("password");
		const name = document.getElementById("name");

		let isValid = true;

		if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
			setEmailError(true);
			setEmailErrorMessage("Please enter a valid email address.");
			isValid = false;
		} else {
			setEmailError(false);
			setEmailErrorMessage("");
		}

		if (!password.value || password.value.length < 6) {
			setPasswordError(true);
			setPasswordErrorMessage("Password must be at least 6 characters long.");
			isValid = false;
		} else {
			setPasswordError(false);
			setPasswordErrorMessage("");
		}

		if (!name.value || name.value.length < 1) {
			setNameError(true);
			setNameErrorMessage("Name is required.");
			isValid = false;
		} else {
			setNameError(false);
			setNameErrorMessage("");
		}

		return isValid;
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);

		await auth.signup({
			name: data.get("name"),
			email: data.get("email"),
			password: data.get("password"),
		});
	};

	const title = `${
		settings?.wedding_details?.bride?.name?.charAt(0).toUpperCase() ?? "B"
	}&${settings?.wedding_details?.groom?.name?.charAt(0).toUpperCase() ?? "G"}`;

	return (
		<Box
			position='relative'
			height='100vh'
			width='100vw'
			display='flex'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			overflow='hidden'
			flex='1'>
			<LoginContainer
				direction='column'
				justifyContent='space-between'
				sx={{
					justifyContent: "center",
					height: "100dvh",
					p: 2,
				}}>
				<LoginCard
					variant='outlined'
					title={title}
					subtitle='Sign Up'
					font={settings?.font}>
					<Box
						component='form'
						onSubmit={handleSubmit}
						sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
						<FormControl>
							<FormLabel htmlFor='name'>Full name</FormLabel>
							<TextField
								autoComplete='name'
								name='name'
								required
								fullWidth
								id='name'
								placeholder='Jon Snow'
								error={nameError}
								helperText={nameErrorMessage}
								color={nameError ? "error" : "primary"}
							/>
						</FormControl>
						<FormControl>
							<FormLabel htmlFor='email'>Email</FormLabel>
							<TextField
								required
								fullWidth
								id='email'
								placeholder='your@email.com'
								name='email'
								autoComplete='email'
								variant='outlined'
								error={emailError}
								helperText={emailErrorMessage}
								color={passwordError ? "error" : "primary"}
							/>
						</FormControl>
						<FormControl>
							<FormLabel htmlFor='password'>Password</FormLabel>
							<TextField
								required
								fullWidth
								name='password'
								placeholder='••••••'
								type='password'
								id='password'
								autoComplete='new-password'
								variant='outlined'
								error={passwordError}
								helperText={passwordErrorMessage}
								color={passwordError ? "error" : "primary"}
							/>
						</FormControl>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							onClick={validateInputs}>
							Sign up
						</Button>
						<Typography sx={{ textAlign: "center" }}>
							Already have an account?{" "}
							<span>
								<Link
									component={LinkBehavior}
									variant='body2'
									sx={{ alignSelf: "center" }}>
									Sign in
								</Link>
							</span>
						</Typography>
					</Box>
				</LoginCard>
			</LoginContainer>
		</Box>
	);
}
