import { React, Children, cloneElement, forwardRef } from "react";
import { styled } from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { ButtonBase } from "@mui/material";

const LoginCard = styled(MuiCard)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignSelf: "center",
	width: "100%",
	padding: theme.spacing(4),
	gap: theme.spacing(2),
	margin: "auto",
	[theme.breakpoints.up("sm")]: {
		maxWidth: "450px",
	},
	boxShadow:
		"hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
}));

const LinkBehavior = forwardRef((props, ref) => (
	<Link ref={ref} to='/' {...props} />
));

export default function Card({ children, title, subtitle, font }) {
	return (
		<LoginCard>
			<Box justifyContent='center' alignItems='center' display='flex'>
				<ButtonBase
					component={LinkBehavior}
					disableRipple={true}
					sx={{
						alignItems: "center", // Aligns the icon and text vertically
						fontFamily: font?.style,
						fontSize: "clamp(2rem, 10vw, 2.15rem)",
						color: font?.color,
					}}>
					{title}
				</ButtonBase>
			</Box>
			<Divider />
			<Typography
				component='h1'
				variant='h4'
				sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}>
				{subtitle}
			</Typography>
			{Children.map(children, (child) => {
				return cloneElement(child);
			})}
		</LoginCard>
	);
}
