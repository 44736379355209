import React from "react";
import { Typography } from "@mui/material";

export default function CountDownTypography({ text, size, font }) {
	return (
		<Typography
			sx={{
				fontFamily: font?.style,
				fontSize: size,
				color: font?.color ?? "#fff",
			}}>
			{text}
		</Typography>
	);
}
