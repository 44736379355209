import React from "react";
import { Paper, Grid } from "@mui/material";
import CountDownTypography from "../CountDownTypography";
import { isMobile } from "react-device-detect";

const getSizes = () => {
	if (isMobile) {
		return {
			box: "20vw",
			value: "9vw",
			text: "4vw",
		};
	}

	return {
		box: "11vw",
		value: "5vw",
		text: "2vw",
	};
};

export default function CountDownItem({ value, text, font }) {
	const sizes = getSizes();
	return (
		<Grid item>
			<Paper
				sx={{
					height: sizes.box,
					width: sizes.box,
					backgroundColor: "rgba(0, 0, 0, 0.5)",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
				}}>
				<CountDownTypography text={value} size={sizes.value} font={font} />
				<CountDownTypography text={text} size={sizes.text} font={font} />
			</Paper>
		</Grid>
	);
}
