import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "App.css";
import ConfigProvider from "hooks/ConfigProvider";
import AuthProvider from "hooks/AuthProvider";
import Home from "pages/Home";
import SignUp from "pages/SignUp";
import SignIn from "pages/SignIn";
import Admin from "pages/Admin";
import User from "pages/User";
import PrivateRoute from "router/PrivateRoute";

import settings from "settings/application.json";

export default function App() {
	const [mode, setMode] = useState("dark");
	const defaultTheme = createTheme({ palette: { mode } });

	return (
		<AuthProvider>
			<ThemeProvider theme={defaultTheme}>
				<div className='app'>
					<CssBaseline />
					<ConfigProvider>
						<Routes>
							<Route path='/'>
								<Route index path='/' element={<Home />} />
								<Route element={<PrivateRoute />}>
									<Route path='/admin' element={<Admin />} />
								</Route>
								<Route element={<PrivateRoute />}>
									<Route path='/user' element={<User />} />
								</Route>
								<Route
									path='/signup'
									element={<SignUp settings={settings} />}
								/>
								<Route
									path='/signin'
									element={<SignIn settings={settings} />}
								/>
							</Route>
						</Routes>
					</ConfigProvider>
				</div>
			</ThemeProvider>
		</AuthProvider>
	);
}
