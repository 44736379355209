import { React, Children, cloneElement } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

const LoginContainer = styled(Stack)(({ theme }) => ({
	padding: 20,
	marginTop: "10vh",
	"&::before": {
		content: '""',
		display: "block",
		position: "absolute",
		zIndex: -1,
		inset: 0,
	},
}));

export default function Container({ children }) {
	return (
		<LoginContainer>
			{Children.map(children, (child) => {
				return cloneElement(child);
			})}
		</LoginContainer>
	);
}
