import { forwardRef, useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SideBar from "components/SideBar";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Link } from "react-router-dom";
import { ButtonBase } from "@mui/material";
import { useAuth } from "hooks/AuthProvider";

const backgroundColor = "rgba(0, 0, 0, 0.5)";
const settings = [];
const LinkBehavior = forwardRef((props, ref) => (
	<Link ref={ref} to='/signin' {...props} />
));

export default function NavBar({ title, items, font }) {
	const [mobileOpen, setMobileOpen] = useState(false);
	const [anchorElUser, setAnchorElUser] = useState(null);
	const auth = useAuth();

	const handleDrawerToggle = () => {
		setMobileOpen((prevState) => !prevState);
	};

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleSignoutAndCloseUserMenu = async () => {
		await auth.signout();
		setAnchorElUser(null);
	};

	useEffect(() => {
		console.log(auth.user);
	}, [auth.user]);

	function stringToColor(string) {
		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}

		let color = "#";

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			color += `00${value.toString(16)}`.slice(-2);
		}
		/* eslint-enable no-bitwise */

		return color;
	}

	function stringAvatar() {
		const name = auth?.user?.data?.displayName ?? "User";
		return {
			sx: {
				bgcolor: stringToColor(name),
			},
			children:
				name.split(" ").length > 1
					? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
					: `${name.split(" ")[0][0]}`,
		};
	}

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<AppBar
				component='nav'
				sx={{
					backgroundColor: backgroundColor,
				}}>
				<Toolbar
					sx={{
						display: { sm: "flex" },
						justifyContent: { sm: "space-between" }, // Separates the left-aligned and right-aligned items
						alignItems: "center", // Vertically centers the content
						padding: "1rem 2rem",
					}}>
					<IconButton
						color='inherit'
						aria-label='open drawer'
						edge='start'
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { sm: "none" } }}>
						<MenuIcon />
					</IconButton>
					<Box display='flex' justifyContent='flex-start' sx={{ flexGrow: 1 }}>
						<ButtonBase
							component={LinkBehavior}
							disableRipple={true}
							sx={{
								display: { xs: "block", sm: "flex" },
								alignItems: "center", // Aligns the icon and text vertically
								fontFamily: font?.style,
								fontSize: "1.5rem",
								color: font?.color,
							}}>
							{title}
						</ButtonBase>
					</Box>
					<Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
						{items?.map((item) => (
							<Button
								key={`button-${item.replace(/\s+/g, "-").toLowerCase()}`}
								sx={{ color: "#fff" }}>
								{item}
							</Button>
						))}
					</Box>
					<Box
						sx={{
							flexGrow: 0,
							display:
								auth?.user?.status === "AUTHENTICATED" ? "block" : "none",
						}}>
						<Tooltip title='Open settings'>
							<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
								<Avatar {...stringAvatar()} />
							</IconButton>
						</Tooltip>
						<Menu
							sx={{ mt: "45px" }}
							id='menu-appbar'
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							open={Boolean(anchorElUser)}
							onClose={handleCloseUserMenu}>
							<MenuItem
								key={auth?.user?.id ?? "your-account-id"}
								onClick={handleCloseUserMenu}>
								<Typography sx={{ textAlign: "center" }}>
									{auth?.user?.data?.displayName ?? "Your Account"}
								</Typography>
							</MenuItem>
							<MenuItem
								key='signout-item'
								onClick={handleSignoutAndCloseUserMenu}>
								<Typography sx={{ textAlign: "center" }}>
									{"Sign Out"}
								</Typography>
							</MenuItem>
							{settings.map((setting) => (
								<MenuItem key={setting} onClick={handleCloseUserMenu}>
									<Typography sx={{ textAlign: "center" }}>
										{setting}
									</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>
				</Toolbar>
			</AppBar>
			<SideBar
				title={title}
				items={items}
				onClick={handleDrawerToggle}
				open={mobileOpen}
				font={font}
			/>
		</Box>
	);
}
