import { useEffect, useState, forwardRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ForgotPassword from "components/Login/ForgotPassword";
import { GoogleIcon, FacebookIcon } from "components/Login/CustomIcons";
import LoginContainer from "components/Login/Container";
import LoginCard from "components/Login/Card";
import { Link as RouterDomLink } from "react-router-dom";
import { useAuth } from "hooks/AuthProvider";

const LinkBehavior = forwardRef((props, ref) => (
	<RouterDomLink ref={ref} to='/signup' {...props} />
));

export default function SignIn({ settings }) {
	const auth = useAuth();
	const [emailError, setEmailError] = useState(false);
	const [emailErrorMessage, setEmailErrorMessage] = useState("");
	const [passwordError, setPasswordError] = useState(false);
	const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		await auth.signin({
			email: data.get("email"),
			password: data.get("password"),
		});
	};

	useEffect(() => {
		if (auth.user.status != "AUTHENTICATED") {
			if (auth.user.status === "NOT_AUTHORIZED") {
				setEmailError(true);
				setEmailErrorMessage("Please check your credentials.");
				setPasswordError(true);
			}
			if (auth.user.status === "FAILED") {
				setEmailError(true);
				setEmailErrorMessage(
					"Ocurred an unexpected error. Please try again later."
				);
				setPasswordError(true);
			}
		}
	}, [auth.user]);

	const validateInputs = () => {
		const email = document.getElementById("email");
		const password = document.getElementById("password");

		let isValid = true;

		if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
			setEmailError(true);
			setEmailErrorMessage("Please enter a valid email address.");
			isValid = false;
		} else {
			setEmailError(false);
			setEmailErrorMessage("");
		}

		if (!password.value || password.value.length < 6) {
			setPasswordError(true);
			setPasswordErrorMessage("Password must be at least 6 characters long.");
			isValid = false;
		} else {
			setPasswordError(false);
			setPasswordErrorMessage("");
		}

		return isValid;
	};

	const title = `${
		settings?.wedding_details?.bride?.name?.charAt(0).toUpperCase() ?? "B"
	}&${settings?.wedding_details?.groom?.name?.charAt(0).toUpperCase() ?? "G"}`;

	return (
		<Box
			position='relative'
			height='100vh'
			width='100vw'
			display='flex'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			overflow='hidden'
			flex='1'>
			<LoginContainer direction='column' justifyContent='space-between'>
				<LoginCard
					variant='outlined'
					title={title}
					subtitle='Sign in'
					font={settings?.font}>
					<Box
						component='form'
						onSubmit={handleSubmit}
						noValidate
						sx={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
							gap: 2,
						}}>
						<FormControl>
							<FormLabel htmlFor='email'>Email</FormLabel>
							<TextField
								error={emailError}
								helperText={emailErrorMessage}
								id='email'
								type='email'
								name='email'
								placeholder='your@email.com'
								autoComplete='email'
								autoFocus
								required
								fullWidth
								variant='outlined'
								color={emailError ? "error" : "primary"}
								sx={{ ariaLabel: "email" }}
							/>
						</FormControl>
						<FormControl>
							<Box sx={{ display: "flex", justifyContent: "space-between" }}>
								<FormLabel htmlFor='password'>Password</FormLabel>
								<Link
									component='button'
									onClick={handleClickOpen}
									variant='body2'
									sx={{ alignSelf: "baseline" }}>
									Forgot your password?
								</Link>
							</Box>
							<TextField
								error={passwordError}
								helperText={passwordErrorMessage}
								name='password'
								placeholder='••••••'
								type='password'
								id='password'
								autoComplete='current-password'
								autoFocus
								required
								fullWidth
								variant='outlined'
								color={passwordError ? "error" : "primary"}
							/>
						</FormControl>
						<FormControlLabel
							control={<Checkbox value='remember' color='primary' />}
							label='Remember me'
						/>
						<ForgotPassword open={open} handleClose={handleClose} />
						<Button
							type='submit'
							fullWidth
							variant='contained'
							onClick={validateInputs}>
							Sign in
						</Button>
						<Typography sx={{ textAlign: "center" }}>
							Don&apos;t have an account?{" "}
							<span>
								<Link
									component={LinkBehavior}
									variant='body2'
									sx={{ alignSelf: "center" }}>
									Sign up
								</Link>
							</span>
						</Typography>
					</Box>
					{/* <Divider>or</Divider>
					<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
						<Button
							type='submit'
							fullWidth
							variant='outlined'
							onClick={() => alert("Sign in with Google")}
							startIcon={<GoogleIcon />}>
							Sign in with Google
						</Button>
						<Button
							type='submit'
							fullWidth
							variant='outlined'
							onClick={() => alert("Sign in with Facebook")}
							startIcon={<FacebookIcon />}>
							Sign in with Facebook
						</Button>
					</Box> */}
				</LoginCard>
			</LoginContainer>
		</Box>
	);
}
