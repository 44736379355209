import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
	const [user, setUser] = useState({ status: "NOT_AUTHENTICATED" });
	const navigate = useNavigate();

	const signup = async (userAccount) => {
		try {
			const response = await axios.post("/api/v1/authenticator/signup", {
				name: userAccount.name,
				email: userAccount.email,
				password: userAccount.password,
			});

			await createUser({
				id: response.data.id,
				name: userAccount.name,
				displayName: userAccount.name,
				email: response.data.email,
				role: "User",
			});

			navigate("/");
		} catch (error) {
			if (
				error.response?.status === 400 &&
				error.response?.data?.code === "ALREADY_EXISTS_ERROR"
			) {
				setUser({ status: "NOT_AUTHENTICATED" });
				navigate("/signin");
			}
			setUser({ status: "FAILED" });
		}
	};

	const signin = async (credentials) => {
		try {
			const response = await axios.post("/api/v1/authenticator/signin", {
				email: credentials.email,
				password: credentials.password,
			});

			await getUser(response.data);
		} catch (error) {
			if (
				error.response?.status === 401 &&
				error.response?.data?.code === "NOT_AUTHORIZED_ERROR"
			) {
				setUser({ status: "NOT_AUTHORIZED" });
			} else {
				setUser({ status: "FAILED" });
			}
		}
	};

	const signout = async () => {
		try {
			await axios.post(
				"/api/v1/authenticator/signout",
				{},
				{ withCredentials: true }
			);
			setUser({ status: "NOT_AUTHENTICATED" });
		} catch (error) {
			console.error("Error fetching data:", error);
			throw new Error(error);
		}
	};

	const createUser = async (user) => {
		const response = await axios.post("/api/v1/user", {
			id: user.id,
			name: user.name,
			displayName: user.displayName,
			email: user.email,
			role: "User",
		});

		setUser({ data: response.data, status: "AUTHENTICATED" });
	};

	const getUser = async (account) => {
		try {
			const response = await axios.get(`/api/v1/user/${account.id}`);

			setUser({ data: response.data, status: "AUTHENTICATED" });

			navigate("/");
		} catch (error) {
			if (
				error.response?.status === 404 &&
				error.response?.data?.code === "NOT_FOUND_ERROR"
			) {
				setUser({ data: account, status: "AUTHENTICATED" });
				navigate("/user");
			} else {
				setUser({ status: "FAILED" });
			}
		}
	};

	const validateToken = async () => {
		try {
			const response = await axios.post(
				"/api/v1/authenticator/validate",
				{},
				{ withCredentials: true }
			);

			return response.status === 201;
		} catch (error) {
			return false;
		}
	};

	return (
		<AuthContext.Provider
			value={{ user, signin, signout, signup, validateToken }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;

export const useAuth = () => {
	return useContext(AuthContext);
};
