import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

function SideBarItem({ text, onClick }) {
	return (
		<ListItem disablePadding>
			<ListItemButton sx={{ textAlign: "center" }}>
				<ListItemText primary={text} />
			</ListItemButton>
		</ListItem>
	);
}

export default SideBarItem;
