import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function Copyright() {
	return (
		<Typography variant='body2' sx={{ color: "#fff", mt: 1 }}>
			{"Copyright © "}
			<Link color='#fff' href='https://jasgoncalves.com/'>
				Jorge Goncalves
			</Link>
			&nbsp;
			{new Date().getFullYear()}
		</Typography>
	);
}

export default function Footer() {
	return (
		<React.Fragment>
			<Container
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					py: { xs: 3, sm: 4 },
					textAlign: { sm: "center", md: "left" },
				}}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						pt: { xs: 4, sm: 8 },
						width: "100%",
						borderTop: "1px solid",
						borderColor: "divider",
					}}>
					<Copyright />
					<Stack
						direction='row'
						spacing={1}
						useFlexGap
						sx={{ justifyContent: "left", color: "#fff" }}>
						<IconButton
							color='inherit'
							size='small'
							href='https://github.com/jasgoncalves'
							aria-label='GitHub'
							sx={{ alignSelf: "center" }}>
							<FacebookIcon />
						</IconButton>
						<IconButton
							color='inherit'
							size='small'
							href='https://www.linkedin.com/in/jorge-gon%C3%A7alves-77b56380/'
							aria-label='LinkedIn'
							sx={{ alignSelf: "center" }}>
							<LinkedInIcon />
						</IconButton>
					</Stack>
				</Box>
			</Container>
		</React.Fragment>
	);
}
