import React from "react";
import Box from "@mui/material/Box";
import Header from "components/Header";
import Footer from "components/Footer";
import { useConfig } from "hooks/ConfigProvider";

export default function Home() {
	const { config, setConfig } = useConfig();
	return (
		<Box>
			<Header
				weddingDetails={config.wedding_details}
				language={config.default_language}
				backgroundImage={config.header.background_image}
				font={config.font}
				items={config.header.items}
			/>
			<Footer />
		</Box>
	);
}
