import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import SideBarItem from "./SideBarItem";

function SideBar({ title, font, items, open, container, onClick }) {
	const drawerWidth = "45vw";
	return (
		<nav>
			<Drawer
				container={container}
				variant='temporary'
				open={open}
				onClose={onClick}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{
					display: { xs: "block", sm: "none" },
					"& .MuiDrawer-paper": {
						boxSizing: "border-box",
						width: drawerWidth,
					},
				}}>
				<Box onClick={onClick} sx={{ textAlign: "center" }}>
					<Typography variant='h6' sx={{ my: 2, fontFamily: font?.style }}>
						{title}
					</Typography>
					<Divider />
					<List>
						{items?.map((item) => (
							<SideBarItem
								key={`sidebar-item-${item.replace(/\s+/g, "-").toLowerCase()}`}
								text={item}
							/>
						))}
					</List>
				</Box>
			</Drawer>
		</nav>
	);
}

export default SideBar;
